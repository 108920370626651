import request from '@/service/lib/request';


export async function queryShopUserList(params) {
  return request({
      url: '/store/shopusers',
      method: 'get',
      params,
  });
}
export async function queryShopUser(id) {
  return request({
      url: '/store/shopuser/'+id,
      method: 'get',
  });
}
export async function editShopUser(params) {
  return request({
      url: '/store/shopuser',
      method: 'post',
      data: params,
  });
}
export async function deleteShopUser(id) {
  return request({
      url: '/store/shopuser/'+id,
      method: 'delete',
  });
}
