
<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card class="SearchBox" v-loading="loading">
      <el-row>
        <el-form :model="searchFormData" :inline="true">
          <el-form-item label="title" prop="title">
            <el-input v-model="searchFormData.title"></el-input>
          </el-form-item>
          <el-button type="primary" @click="() => ToSearch()">搜索</el-button>
          <el-button type="primary" @click="() => ToAddPage()" style="float:right;">新增</el-button>
        </el-form>
      </el-row>
    </el-card>
    <el-card>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="名称" width="180" />
        <el-table-column prop="appid" label="APPID" width="180" />
        <el-table-column prop="createDateTime" label="创建时间" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination layout="prev, pager, next" :total="pageInfo.totalCount" :current-page="pageInfo.pageIndex" :page-size="pageInfo.pageSize" />
      </div>
    </el-card>
  </div>
</template>
<script>
import { queryShopUserList, editShopUser, queryShopUser, deleteShopUser } from '@/service/shopuser.js';
import { shopFormRule } from '@/utlis/rules/activityRule.js';
export default {
  data () {
    return {
      formRule: shopFormRule,
      showDialog: false,
      loading: false,
      pageInfo: {
        pageIndex: 1,
        pageSize: 30,
        totalCount: 0,
      },
      tableData: [],
      formData: {
        id: 0,
        title: '',
        appid: '',
        appsecret: '',
        apisecret: '',
      },
      searchFormData: {
        title: '',
      }
    }
  },
  computed: {
    shopDialogState () {
      if (this.formData.id > 0)
        return "编辑";
      else
        return "新增";
    }
  },
  created () {
    this.DataBinding();
  },
  methods: {
    //加载数据
    DataBinding () {
      this.loading = true;
      let searchParam = Object.assign(this.pageInfo, this.searchFormData);
      console.log(searchParam)

      queryShopUserList(searchParam).then(res => {
        this.tableData = res.data.data;
        this.pageInfo.pageIndex = res.data.pageIndex,
          this.pageInfo.pageSize = res.data.pageSize,
          this.pageInfo.totalCount = res.data.totalCount,
          this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //新增
    ToAddPage () {
      this.showDialog = true;
    },
    //关闭窗口
    handleClose () { },
    //关闭弹窗
    CloseDialog () {
      this.showDialog = false;
      this.DataBinding();
    },
    //手动搜索
    ToSearch () {
      this.DataBinding();
    }
  }
}
</script>
<style scoped Lang="scss">
.SearchBox {
  margin-bottom: 10px;
}
</style>